
/*********************
*     General Css
*
***********************/
header, .business_bliss_main_header {
  transition: all 0.5s ease 0s;

}

.header_sticky {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease 0s;
  position: relative;
  z-index: 99999;
}

/*transparent header*/
.transparent_header {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 99999;
  background: transparent;
}

.transparent_header .business_bliss_top_bar {
  background: transparent;
  border: none;
}

.transparent_header .business_bliss_main_header .site-branding h1 a,
.transparent_header .business_bliss_main_header .site-branding p,
.transparent_header .main-navigation ul li a,
.transparent_header .search_main_menu a,
.transparent_header  .business_bliss_top_info_bar ul li a  ,
.transparent_header .main-navigation div > ul > li.menu-item-has-children:before {
  color: #FFF;
}

.transparent_header .main-navigation ul li a {
  font-size: 15px;
}

.transparent_header .main-navigation ul.sub-menu li a {
  color: #0e0e0e;
}

.transparent_header .main-navigation ul li:hover > a,
.transparent_header .main-navigation ul li.current-menu-item > a,
.transparent_header .main-navigation ul li.active > a {
  color: #d59b2d;
}

.transparent_header .main-navigation div > ul > li.menu-item-has-children:hover:before, .transparent_header .main-navigation ul ul li.menu-item-has-children:hover:before {
  color: #d59b2d;
}

.transparent_header.full_width .main-navigation ul li ul li ul, .transparent_header.full_width .main-navigation ul li ul {
  width: 180px;
}

.transparent_header.full_width .main-navigation ul li {
  margin: 0 3px;
}

.transparent_header.full_width .business_bliss_main_header {
  box-shadow: none;

  min-height: inherit;
}

.transparent_header.full_width .logo {
}

.transparent_header.full_width .site-branding {
  text-align: center;
}

.transparent_header.full_width .search_main_menu {
  padding: 25px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
}

.transparent_header.full_width .main-navigation ul {
  margin: 0;
}

.transparent_header.full_width .main-navigation ul.sub-menu {
  margin: 0;
}

/*transparent header*/


@keyframes smoothScroll {
  0% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*header search bar*/
.top-search {
  padding: 7px 0;
  display: none;
}

.top-search-bg {
  background-color: #d59b2d;
}

.top-search input.form-control {
  background-color: transparent;
  border: none;
  -moz-box-shadow: 0px 0px 0px;
  -webkit-box-shadow: 0px 0px 0px;
  -o-box-shadow: 0px 0px 0px;
  box-shadow: 0px 0px 0px;
  color: #fff;
  height: 40px;
  padding: 0 15px;
}

.top-search input.form-control::placeholder {
  color: #FFF;
  opacity: 1; /* Firefox */
}

.top-search input.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #FFF;
}

.top-search input.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: #FFF;
}

.top-search .input-group-addon {
  background-color: transparent;
  border: none;
  color: #fff;
  padding-left: 0;
  padding-right: 0;
  line-height: 36px;
  font-size: 16px;
}

.top-search .input-group-addon.close-search {
  cursor: pointer;
  margin-top: 3px;
}

.top-search .input-group-addon {
  background-color: transparent;
  border: none;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 36px;
  font-size: 16px;
}

.search-form_submit {
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 7px;
}

.fa-search:before {
  content: "\f002";
  font-size: 15px;
}

.business_bliss_top_bar {
  display: block;
  position: relative;
  z-index: 999;
  padding: 7px 0;
  border-bottom: 1px solid #dfdfdf;

}

.top-bar-bg {
  background: #062d55;
}

.business_bliss_top_info_bar {
  display: block;
  vertical-align: middle;
  ul {
    padding: 0;
    margin: 0;
    li {
      font-weight: 500;
      color: #000;
      list-style-type: none;
      font-size: 13px;
      padding: 0 5px 0;
      display: inline-block;

      a{

      }
      i {
        font-size: 16px;
        color: #D59B2D;
        margin-right: 8px;
        margin-top: 0;
        display: inline-block;
        vertical-align: text-bottom
      }
    }
  }
}


.transparent_header .business_bliss_top_info_bar ul li i, .transparent_header .business_bliss_top_social_icon  ul li a{
  color: #d59b2d;
}
.transparent_header .business_bliss_top_social_icon li a, .transparent_header .team_social_icon li a{
  color: #d59b2d;
}

/*top bar social icom*/
.business_bliss_top_social_icon, .team_social_icon {
  padding: 0;
  margin: 0;
}

.business_bliss_top_social_icon li, .team_social_icon li {
  font-size: 14px;
  list-style-type: none;
  float: left;
  text-align: center;
  margin: 0;
  padding: 0 7px
}

.business_bliss_top_social_icon li:last-child, .team_social_icon li:last-child {
  padding-right: 0
}

.business_bliss_top_social_icon li:last-child a, .team_social_icon li:last-child a {
  padding-right: 0
}

.business_bliss_top_social_icon li a, .team_social_icon li a {
  line-height: 28px;
  transition-duration: .3s;
  color: #D59B2D;
  padding: 0 3px
}

.team_social_icon li a {
  color: #939393;
}

.business_bliss_top_social_icon li a:hover, .team_social_icon li a:hover {
  color: #d59b2d
}


/*main header*/
.business_bliss_main_header {
  display: block;
  z-index: 999;
  min-height: 70px;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #dfdfdf;
}
.transparent_header .business_bliss_main_header{
  border: none;
}

.header_fixed {
  position: fixed;
  background: #FFF;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999999999;
  box-shadow: rgba(0, 0, 0, .117647) 0 1px 3px;
  animation: smoothScroll 1s forwards;
}

.main-header-bg {
  background: #FFF;
}
.business_bliss_main_header .site-branding img{
  padding: 7px 0;
}
.business_bliss_main_header .site-branding h1 {
  margin: 5px 0px 0px 0px;
  text-transform: uppercase;
}

.business_bliss_main_header .site-branding h1 a {
  color: #000;
  font-weight: 700;
  font-size: 27px;
}

.business_bliss_main_header .site-branding p {
  color: #000;
  margin: 0;
  font-size: 14px;
}

.search_main_menu {
  float: right;
  padding: 14px 0px 0px 16px;
  line-height: 2.2;
}

.search_main_menu a {
  color: #000
}


/*-------------------------------------- Banner -----------------------------------------*/