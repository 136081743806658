/*--------------------------------------------------------------
## Menus
--------------------------------------------------------------*/
span.sub-toggle {
  display: none;
  position: absolute;
  top: 6px;
  right: 2px;
  width: 42px;
  height: 42px;
  text-align: center;
  background: transparent;
  color: $color_white;
  line-height: 42px;
  z-index: 99;
  cursor: pointer;
}

.main-menu-bg {
  background: #062D55;
}

.main-navigation {
  background: #efefef;
  position: relative;
}

.main-navigation ul {
  margin: 0;
  padding: 0px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: block;
  }
}

.main-navigation ul li {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0px;
  text-align: left;
  padding: 0;
  .cart_count {
    position: absolute;
    color: #FFF;
    font-size: 12px;
    top: 0;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    right: 0;
    line-height: 2;
    background: $primary_color;
    text-align: center;
  }
}

.main-navigation ul ul li {
  margin: 0;
  padding: 0;

}

.main-navigation ul li a {
  display: block;
  color: $primary_color;
  font-size: 14px;
  padding: 6px 14px;
  transition: all 0.3s ease;
  line-height: 2.2;
  font-family: $font_main;
  font-weight: 600;
}

.main-navigation ul.nav-menu > li > a {
  padding: 7px 20px;
}

.main-navigation ul li:hover > a,
.main-navigation ul li.current-menu-item > a,
.main-navigation ul li.active > a {
  color: $secondary_color;

  .cart_count {

    background: $secondary_color;
  }
}

.main-navigation ul li ul {
  left: 0;
  top: 79%;
  margin: 0;
  width: 210px;
  display: block;
  list-style: none;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  background-color: #FFF;
  z-index: 999;
  transform: translate(0, 10px);
  transition: all .3s ease-in-out;
  box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.3);
  padding: 0;
}

.main-navigation ul li:last-child ul {
  left: -143px;
}

.main-navigation ul li ul li {
  position: relative;
  display: block;
  border-bottom: 1px solid #e0e0e0;
}

.main-navigation ul li ul li a {
  font-size: 13px;
  color: #000;
}

.main-navigation ul li ul li a:hover {
  color: #FFF;
  background: #032342;
}

.main-navigation ul li:hover > ul {
  opacity: 1;
  visibility: visible;
}

.main-navigation ul li ul li ul {
  left: 100%;
  top: 0%;
  margin: 0;
  width: 215px;
  display: block;
  list-style: none;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  background-color: #FFF;
  z-index: 1;
  padding: 0;
  transform: translate(0, 10px);
  transition: all .3s ease-in-out;
  box-shadow: 0px 2px 10px -2px rgba(0, 0, 0, 0.3);
}

.main-navigation ul li ul li:hover > ul {
  transform: translate(0, 0);
  opacity: 1;
  visibility: visible;
}

/*menu has children*/
.main-navigation ul > li.menu-item-has-children {
  position: relative;
}

.main-navigation ul > li.menu-item-has-children > a {
  padding: 5px 14px;
}

.main-navigation div > ul > li.menu-item-has-children:before {
  content: '\f107';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: $primary_color;
  right: 2px;
  font-size: 14px;
}

.main-navigation ul ul li.menu-item-has-children:before {
  content: '\f105';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: $color_white;
  right: 9px;
}

.main-navigation div > ul > li.menu-item-has-children:hover:before {
  color: #151f52;
}

.main-navigation ul ul li.menu-item-has-children:before {
  color: $color_white;
}

.main-navigation ul ul li.menu-item-has-children:hover:before {
  color: #151f52;
}

.site-main .comment-navigation, .site-main
.posts-navigation, .site-main
.post-navigation {
  margin: 0 0 1.5em;
  overflow: hidden;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
  float: left;
  width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
  float: right;
  text-align: right;
  width: 50%;
}

.main-menu-toggle {
  display: none;
}

