/*--------------------------------------------------------------
## Footer Widget
--------------------------------------------------------------*/
.site-footer {
  background: #000;
  color: #fff;
  position: relative;
  .logo {
    width: 200px;
  }

  /* &:before {
     content: "";
     position: absolute;
     left: 0;
     top: 0;
     z-index: 0;
     width: 100%;
     height: 100%;
     background-image: url(../images/bg-line.png);
     background-position: 50%;
     background-size: cover;
     background-repeat: no-repeat;
   }*/
}

.upper-footer .row {
  padding: 50px 0 20px 0;
  position: relative;
}

.site-footer .widget {
  margin: 2em 0;
  z-index: 9;
}

.site-footer .widget .widget-title {
  width: 100%;
  margin-top: 0;
  text-transform: uppercase;
  display: inline-block;
  color: #FFF;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 1.5px;
  line-height: 1.6;
  padding-bottom: 5px;
  position: relative;
  margin-bottom: 20px;
}

/*.site-footer .widget .widget-title:before {
    height: 3px;
    content: "";
    position: absolute;
    width: 60px;
    background: #3085cc;
    bottom: 0;
    left: 0;
}

.site-footer .widget .widget-title:after {
    height: 3px;
    content: "";
    position: absolute;
    width: 15px;
    background: #fff;
    bottom: 0;
    left: 67px;
}*/

.site-footer .widget ul,
.widget_tag_cloud .tagcloud {
  padding: 0;
}

.site-footer .widget ul li {

  margin-bottom: 0;
  font-size: 13px;
}

.site-footer .widget ul li:last-child {
  border: 0;
}

.site-footer .widget ul li,
.site-footer .widget ul li a,
.site-footer .widget a,
.site-footer .widget ul li:before,
.site-footer .widget a::before,
.site-footer .calendar_wrap table td,
.site-footer .calendar_wrap table th {
  color: #fff;
}

.site-footer .widget a:hover,
.site-footer .widget a:hover::before,
.site-footer .widget li:hover::before {
  color: #3085cc;
}

.site-footer .widget_recent_entries a {
  padding-left: 15px;
}

/*--------------------------------------------------------------
## Sub Footer Copyright & Social Icon
--------------------------------------------------------------*/
.lower-footer {
  position: relative;
}

.lower-footer .row {
  padding: 20px 0;
  position: relative;
}

.lower-footer .row > div {
  align-self: center;
}

.lower-footer {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.lower-footer .copyright {
  display: block;
  margin: 0;
  color: #FFF;
  font-size: 13px;

  a {
    color: #fb5b21;
  }
}

.lower-footer ul.social-media {
  text-align: right;
  padding: 6px 0 3px 0;
  margin: 0;
}

.lower-footer ul li {
  display: inline-block;
  margin: 0.15em;
  position: relative;
  font-size: 15px;
  height: 26px;
  color: #f6f5f5;
}

.lower-footer ul li a {
  color: #FFF;
}

.back-to-top {
  background-color: #195f9a;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  text-align: center;
  position: absolute;
  z-index: 999;
  left: calc(50% - 22.5px);
  top: -22.5px;
  transition: all 0.2s;
  color: #FFF;
  i {
    color: #FFF;
  }
  a {
    color: #FFF;
  }
}

.back-to-top:hover {
  color: #FFF;
  background-color: #0e436f;
}

.widget_contact {
  ul {
    margin: 0;
    padding: 0;
    li {
      list-style-type: none;
      padding: 10px 0;
    }
  }
}

/*------------------------------------*/

/*--------------------
Footer Styles
----------------------*/
.footer-wrapper {
  position: relative;
}

.footer-default {
  background: #f8f9fc;
}

.footer-default .footer-left {
  background: -webkit-linear-gradient(305deg, #f81f01 0%, #ee076e 100%);
  background: -o-linear-gradient(305deg, #f81f01 0%, #ee076e 100%);
  background: linear-gradient(145deg, #f81f01 0%, #ee076e 100%);
  padding: 175px 120px;
  position: relative;
  z-index: 2;
  margin-right: -15px;
  border-top-right-radius: 6px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-default .footer-left {
    padding: 120px 80px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-default .footer-left {
    padding: 60px 70px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-default .footer-left {
    padding: 60px 30px;
  }
}

.footer-default .footer-left::before {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-image: url("../images/pattern-1.png");
  content: "";
  z-index: 1;
  opacity: 0.5;
  border-top-right-radius: 6px;
}

.footer-default .footer-left .inner {
  z-index: 5;
  position: relative;
}

.footer-default .footer-left .inner::after {
  content: url(../images/big-logo.png);
  position: absolute;
  right: 0;
  top: 50%;
  z-index: -1;
  font-size: 240px;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 295px;
  height: 301px;
}

.footer-default .footer-left .inner > span {
  color: #fff;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 4px;
  display: block;
  margin-bottom: 9px;
}

@media only screen and (max-width: 575px) {
  .footer-default .footer-left .inner > span {
    letter-spacing: 1px;
    margin-bottom: 9px;
  }
}

.footer-default .footer-left .inner h2 {
  color: #ffffff;
  font-size: 75px;
  font-weight: 900;
  font-family: 'Montserrat', sans-serif;
  line-height: 90px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-default .footer-left .inner h2 {
    font-size: 50px;
    line-height: 61px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-default .footer-left .inner h2 {
    font-size: 45px;
    line-height: 51px;
  }
}

.footer-default .footer-left .inner a.rn-button-style--2 {
  margin-top: 30px;
  border-color: #ffffff;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
  .footer-default .footer-left .inner a.rn-button-style--2 {
    margin-top: 10px;
    padding: 10px 23px;
  }
}

@media only screen and (max-width: 575px) {
  .footer-default .footer-left .inner a.rn-button-style--2 {
    padding: 7px 16px;
  }
}

.footer-default .footer-left .inner a.rn-button-style--2:hover {
  background: #fff;
  border-color: #fff;
  color: #f9004d;
}

.footer-default .footer-right {
  background-image: url(../images/bg-image-8.jpg);
  padding: 70px 70px 30px 70px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding-left: 120px;
}

.footer-default .footer-right:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-color: #00010c;
  opacity: 0.6;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-default .footer-right {
    padding: 119px 21px;
    padding-left: 70px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .footer-default .footer-right {
    padding: 60px 70px;
  }
}

@media only screen and (max-width: 767px) {
  .footer-default .footer-right {
    padding: 60px 30px;
  }
}

.footer-default .footer-right .footer-widget h4 {
  color: #c6c9d8;
  font-weight: 500;
  margin-bottom: 30px;
  opacity: 0.75;
}

@media only screen and (max-width: 767px) {
  .footer-default .footer-right .footer-widget h4 {
    margin-bottom: 14px;
  }
}

.footer-default .footer-right .footer-widget ul.ft-link {
  padding: 0;
  margin: 0;
  list-style: none;
}

.footer-default .footer-right .footer-widget ul.ft-link li a {
  color: #c6c9d8;
  font-size: 16px;
  opacity: 0.75;
}

.footer-default .footer-right .footer-widget ul.ft-link li:hover a {
  color: #f9004d;
}

.footer-default .footer-right .footer-widget ul.ft-link li + li {
  margin-top: 13px;
}

@media only screen and (max-width: 767px) {
  .footer-default .footer-right .footer-widget ul.ft-link li + li {
    margin-top: 8px;
  }
}

.copyright-text {
  margin-top: 30px;
  text-align: center;
  p {
    color: #c6c9d8;
    font-size: 14px;
    opacity: 0.75;
    margin: 0;
  }
}