.rad_page {
  background: #f8f9fc;
  .section-title, .about-inner {
    padding: 0;
    p {
      font-weight: 400;
      color: #717173;
      font-size: 19px;
      line-height: 30px;
      padding: 0;
      text-align: justify;
    }
  }

  h3.title {
    font-size: 30px;
  }

  .thumbnail {
    position: relative;
    img {
      border-radius: 8px;
      box-shadow: 0 25px 65px rgba(0, 0, 0, 0.1);
    }
  }

  figure {
    position: relative;
    overflow: hidden;
    img {
      max-width: 100%;
    }
    margin-bottom: 15px;
  }
  .btn_style--1:hover {
    color: #009d12;
  }
}

/*----------------------------------------------------------------------
## Product Deatail
------------------------------------------------------------------------*/
.product-detail {
  margin: 40px 0 0 0;
}

.product-detail .photos {
  display: inline;
  width: 100%;

}

.product-detail .photos .mainimage {
  padding-left: 100px;
  margin: 0;
  display: block;
  width: 100%;

}

.product-detail .photos .mainimage figure {
  border: 1px solid #c5c5c5;
}

.product-detail .photos .mainimage .img {
  width: 100%;
}

.product-detail .photos ul.photo-list {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  width: 80px;
  margin-right: 20px;
  float: left;
}

.product-detail .photos li {
  display: block;
  overflow: hidden;
  margin-bottom: 10px;
  border: 1px solid #c5c5c5;
}

.description {
  h3 {
    padding: 15px 0;
    font-weight: 600;
    font-size: 25px;
    margin-top: 35px;
  }
  p {

  }
}

.zoomWrapper {
  overflow: hidden;
}

.product-detail .photos li img {
  width: 100%;
}

.product-detail .photos figure {
  margin: 0;
}

.productDetails {
  display: block;
  padding: 0 30px 30px;
}

.productDetails .titleName {
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 20px;
}

.productDetails .titleName small {
  font-size: 13px;
  font-weight: 400;
  display: block;
}

.productDetails .socialshare {
  position: relative;
  margin-bottom: 30px;
}

.socialshare label {
  font-size: 14px;
  position: absolute;
  top: 0;
  left: 0;
  margin-right: 30px;
  font-weight: 700;
  color: #000000;
  line-height: 24px;
}

.productDetails .socialshare ul {
  list-style: none;
  margin: 0;
  padding: 0 0 0 70px;
}

.productDetails .socialshare ul li {
  display: inline-block;
  margin: 0;
}

.productDetails .socialshare ul li a {
  display: block;
  text-align: center;
  padding: 8px 16px;

  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.facebook {
  background: #3B5998;
  color: white;
}

.twitter {
  background: #55ACEE;
  color: white;
}

.google {
  background: #dd4b39;
  color: white;
}

.linkedin {
  background: #007bb5;
  color: white;
}

.youtube {
  background: #bb0000;
  color: white;
}

/*--------------------------- Contact Us ---------------------------------*/
.contact_form_page {
  margin-bottom: 50px;
  margin-top: 60px;
}

.contact-icon {
  display: block;
  margin-bottom: 30px;
}

.contact-icon .box {
  display: flex;
  align-items: center;
}

.contact-icon .icon-box {
  width: 15%;
}

.contact-icon .text p {
  margin: 0;
}

.contact-icon .contact-inner a {
  font-size: 16px;
  display: block;
  color: #737373;
}

.contact-icon .contact-inner i {
  color: #fff;
  display: inline-block;
  font-size: 26px;
  text-align: center;
  line-height: 48px;
  margin-right: 15px;
  border-radius: 3px;
  width: 50px;
  height: 50px;
  border: 1px solid #000;
  background: #000;
  margin-top: 15px;
}

.contact-icon .contact-inner a span {
  line-height: 30px;
  display: inline-block;
  overflow: hidden;
}

.contact_form .wpcf7 p {
  margin-bottom: 0;
}

.contact_form .wpcf7 label {
  display: inline-block;
  margin-bottom: .5rem;
  width: 100%;
}

.contact_form .wpcf7 input, .contact_form .wpcf7 .wpcf7-textarea {
  background: #f2f2f2;
  width: 100%;
}

.contact_form .wpcf7 .wpcf7-textarea {
  height: 200px;
}

textarea {
  width: 100%;
}

.contact_form input[type="submit"] {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  padding: 12px 28px;
  font-size: 15px;
  line-height: 1.5;
  border-radius: 0px;
  position: relative;
  z-index: 9;
  overflow: hidden;
  letter-spacing: 0.5px;
  background: #000;
  color: $color_white;
  border: 1px solid $primary_color;
  transition: all 0.3s ease;

  &:hover {
    background: $secondary_color;
    color: $color_white;
    border: 1px solid $secondary_color;
    letter-spacing: 1px;
    transition: all 0.3s ease;
  }
}

/***********
*   Testimonials
************/
.business_bliss_testimonial {
  padding: 70px 0;
  background: url("assets/images/testimonial-bg.jpg");
  position: relative;
}

.business_bliss_testimonial .thin_layer {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #000;
  opacity: 0.5;
}
.business_bliss_testimonial .item {
  text-align: center;
}

.business_bliss_testimonial .client-img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 5px solid #ddd;
  text-align: center;
  overflow: hidden;
  margin: 0 auto;
}

.business_bliss_testimonial p {
  opacity: .8;
  max-width: 800px;
  margin: 30px auto;
  color: #FFF;
  text-align: center;
}

.business_bliss_testimonial button.slick-next, .business_bliss_testimonial button.slick-prev {
  position: absolute;
  top: 45%;
  transition: all 0.5s ease;
}

.business_bliss_testimonial button.slick-next,
.business_bliss_testimonial button.slick-prev {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 1;
  width: 68px;
  height: 68px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  z-index: 9;
}

.business_bliss_testimonial button.slick-next {
  right: 0;
}

.business_bliss_testimonial button.slick-prev {
  left: 0;
}

.business_bliss_testimonial .client-text h3 {
  margin-bottom: 0px;
  color: #10b64e;
  font-size: 20px;
  text-align: center;
}

.business_bliss_testimonial h4 {
  font-size: 22px;
  text-align: center;
  color: #fff;
}



/*---------------------*/

.gallery {
  margin-top: 0px;
}

.gallery .gallery-inner {
  margin-bottom: 20px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  min-height: 230px;
  background-position: 50%;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-color: #f1f1f1;
  background-size: cover;
  transition: all .5s;
  @media (max-width: 992px) {
    margin-bottom: 15px;
  }
  h4 {
    background: rgb(34, 65, 142);
    color: #FFF;
    padding: 5px 0;
    margin: 0;
  }
}

.gallery .box .centerd {
  align-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}

.gallery .box .centerd i {
  margin: 0 auto;
}

.gallery .box .overlay {
  position: absolute;
  bottom: 0;
  top: 0;

  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  width: 100%;
  transition: .5s ease;
  opacity: 0;
  color: white;
  font-size: 20px;
  padding: 150px 20px 0 20px;
  text-align: center;

}

.gallery .gallery-inner:hover .overlay {
  opacity: 1;
}

.gallery .orange i {
  color: red;
  font-size: 50px;
}
