/* Links
--------------------------------------------- */
a {
  color: #000;
}

a:visited {
  color: #151f52;
}

a:hover,
a:focus,
a:active {
  color: #1f1f25;
  text-decoration: none;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

/* Forms
--------------------------------------------- */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;
  transition: all ease 0.4s;
  padding: 13px 42px;
  color: $color_white;
  background-color: $primary_color;
  border: 1px solid $primary_color;
  cursor: pointer;
  font-weight: 500;
  border-radius: 0px;
}

button:hover,
input[type="button"]:hover,
input[type="reset"]:hover,
input[type="submit"]:hover {
  border-color: $secondary_color;
  background: $secondary_color;
}

button:active,
button:focus,
input[type="button"]:active,
input[type="button"]:focus,
input[type="reset"]:active,
input[type="reset"]:focus,
input[type="submit"]:active,
input[type="submit"]:focus {
  border-color: $secondary_color;
  background: $secondary_color;
}

/*--------------------------------------------------------------
## buttons
--------------------------------------------------------------*/
.btn_link {
  display: inline-block;
  font-weight: 400;
  color: #FFF;
  text-align: center;
  vertical-align: middle;
  padding: 12px 28px;
  font-size: 17px;
  line-height: 1.5;
  border-radius: 0px;
  position: relative;
  z-index: 9;
  overflow: hidden;
  letter-spacing: 0.5px;
  transition: all 0.3s ease;
}

.btn_gry {
  background: #efefef;
  color: #212529;
  border: 1px solid #212529;
  transition: all 0.3s ease;

  &:visited, &:hover{
    background: #efefef;
    color: #212529;
  }

}

.btn_link {
  background: $primary_color;
  color: $color_white;
  border: 1px solid $primary_color;
  transition: all 0.3s ease;

  &:hover {
    background: $secondary_color;
    color: $color_white;
    border: 1px solid $secondary_color;
    letter-spacing: 1px;
    transition: all 0.3s ease;
  }
}

.btn_black {
  background: #000;
  color: #FFF;
  border: 1px solid #000;
  transition: all 0.3s ease;
  font-weight: 600;
  &:hover {
    letter-spacing: 1px;
    transition: all 0.3s ease;
  }
}

.btn_link--transparent {
  background: transparent;
  color: $color_white;
  border: 1px solid #FFF;
  transition: all 0.3s ease;

  &:hover {
    background: #000;
    color: $color_white;
    border: 1px solid #FFF;
  }
}

.read-more {
  background: transparent;
  color: #d71611;
  border: 5px solid #151f52;
  transition: all 0.3s ease;
  padding: 15px 40px;
  font-size: 21px;
  font-weight: 900;
  &:hover {
    background: #d71611;
    color: $color_white;
    border: 5px solid #d71611;
  }
}

.video-link {
  background: transparent;
  color: $color_white;
  border: 5px solid #151f52;
  transition: all 0.3s ease;
  padding: 15px 40px;
  font-size: 21px;
  font-weight: 900;
  &:hover {
    background: #d71611;
    color: $color_white;
    border: 5px solid #d71611;
  }
}

.bg-primary {
  background-color: #337ab7 !important;
  color: $color_white;
}

.btn_style--1{
  border: 2px solid #009d12;
  background: #009d12;
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 22px 40px;
  border-radius: 6px;
  display: inline-block;
  font-weight: 500;
  transition: 0.3s;
  &:hover{
    border: 2px solid #009d12;
    background: transparent;
    color: #ffffff;
  }
  &:visited{
    color: #ffffff;
  }
}