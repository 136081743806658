/*----------------------------------------------------------------------
## Breadcrumbs
------------------------------------------------------------------------*/
.breadcrumblinks {
  padding: 10px 0;
  margin-bottom: 20px;
}

ul.breadcrumb {
  border-bottom: 1px solid #dddddd;
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  background: #FFF;
}

ul.breadcrumb li {
  display: inline-block;
  position: relative;
  margin-right: 11px;
}

ul.breadcrumb li a {
  color: #3e8675;
}

ul.breadcrumb li:after {
  content: " \f054";
  padding: 0 5px;
  font-size: 10px;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  position: absolute;
  right: -17px;
  top: 5px;
}

ul.breadcrumb li:last-child:after {
  content: none;
}