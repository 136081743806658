/*--------------------------- Banner -------------------------*/
.rad_banner {
  overflow: hidden;
  position: relative;
  .banner-item {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-top: 230px;
    padding-bottom: 284px;
    overflow: hidden;
    position: relative;
    .thin_layer {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: #000;
      opacity: 0.5;
    }

    h2 {
      color: #ffffff;
      font-size: 100px;
      font-weight: 900;
      font-family: 'Montserrat', sans-serif;
    }

    p.description {
      color: #c6c9d8;
      font-size: 24px;
      line-height: 40px;
      margin: 0 0 20px;
      padding: 0 37% 0 0;
      font-weight: 300;
    }
  }
}

.slick-prev, .slick-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.5s;
  opacity: 1;
  width: 68px;
  height: 68px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  z-index: 9;
  &:before {
    line-height: 1;
    opacity: 0.4;
    position: relative;
    top: -2px;
    transition: 0.5s;
    z-index: 2;
  }

}

.slick-prev {
  left: 50px;
  &:before {
    content: url('../images/slick-arrow-left-small.png');
  }
  &:hover {
    left: 30px;
  }
}

.slick-next {
  left: auto;
  right: 50px;
  &:before {
    content: url('../images/slick-arrow-right-small.png');
  }
  &:hover {
    right: 30px;
  }
}

@keyframes customOne {
  from {
    opacity: 0;
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    transform: none;
  }
}

.customOne {
  animation-name: customOne;
}

/*-------------------------------------------------------------------------------------*/

.rad_client_logo {
  background: #f8f9fc;
  .box {
    overflow: hidden;
    padding: 20px;
  }
}

/*-------------------------------------------------------------------------------------*/
.rad_services {
  background: #FFF;
  padding: 60px 0 125px 0;
  .box {
    padding: 60px 45px;
    z-index: 2;
    border-radius: 10px;
    background: #f6f6f6;
    position: relative;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    margin-bottom: 50px;
    &:after {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      background-image: linear-gradient(to right, #f61b10, #ef0963);
      width: 100%;
      height: 100%;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      border-radius: 10px;
    }

    .icon {
      font-size: 54px;
      font-weight: 400;
      margin-bottom: 23px;
      display: inline-flex;
      color: #f9004d;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
    .content {
      .title {
        margin-bottom: 19px;
        font-weight: 500;
        font-size: 25px;
        color: #1f1f25;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      }
      p {
        color: rgba(29, 29, 36, 0.75);
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        margin: 0;
      }
    }
  }
}

/*-----------------------------------------------------------------*/

.rad_about_us {
  background: #f8f9fc;

  .row{
    align-items: center;
  }

  .section-title, .about-inner {
    padding: 0 40px;
    p {
      font-weight: 400;
      color: #717173;
      font-size: 19px;
      line-height: 30px;
      padding: 0;
      text-align: justify;
    }
  }

  h3.title {
    font-size: 30px;
  }

  .thumbnail {
    position: relative;
    img {
      border-radius: 8px;
      box-shadow: 0 25px 65px rgba(0, 0, 0, 0.1);
    }
  }

  .btn_style--1:hover {
    color: #009d12;
  }
}

/*----------------------------------------------------------------------*/
.rad_product {
  .section-title, .about-inner {
    padding: 0 ;
    p {
      font-weight: 400;
      color: #717173;
      font-size: 19px;
      line-height: 30px;
      padding: 0;
      text-align: justify;
    }
  }

  .portfolio {
    position: relative;
    min-height: 600px;
    width: 100%;
    padding-top: 35%;

  }
  .product-slider{
    .item{
      padding: 20px;
    }
  }



  @media only screen and (min-width: 1200px) and (max-width: 1599px) {
    .portfolio {
      min-height: 500px;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .portfolio {
      min-height: 500px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio {
      min-height: 450px;
    }
  }

  @media only screen and (max-width: 767px) {
    .portfolio {
      min-height: 400px;
    }
  }

  .portfolio .thumbnail-inner {
    -webkit-transition: -webkit-transform .28s ease;
    transition: -webkit-transform .28s ease;
    -o-transition: transform .28s ease;
    transition: transform .28s ease;
    transition: transform .28s ease, -webkit-transform .28s ease;
    z-index: 9;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
  }

  .portfolio .thumbnail-inner::before {
    background-color: #00a1ff;
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #00a1ff), to(#000000));
    background-image: -webkit-linear-gradient(#00a1ff 10%, #000000 100%);
    background-image: -o-linear-gradient(#00a1ff 10%, #000000 100%);
    background-image: linear-gradient(#00a1ff 10%, #000000 100%);
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
  }

  .portfolio .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    background-color: #0a0a0a;
    background-image: url(../images/portfolio/portfolio-1.jpg);

    transform: scale(1.13) translateZ(0);
    backface-visibility: hidden;

    transition: -webkit-transform .28s ease;

    transition: transform .28s ease;
    transition: transform .28s ease, -webkit-transform .28s ease;
    z-index: 4;
  }


  .portfolio .thumbnail::after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: ' ';
    z-index: 1;
    display: block;
    border-radius: 5px;
    background-color: rgba(25, 25, 25, 0.37);
  }

  .portfolio .bg-blr-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-color: #0a0a0a;
    background-image: url(../images/portfolio-1.jpg);
    transform: translateY(38px);
    opacity: 0;
    z-index: 1;
  }

  .portfolio .content {
    position: absolute;
    bottom: 40px;
    left: 40px;
    right: 40px;
    max-width: 100% !important;
    z-index: 10;
    padding: 0;
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .portfolio .content {
      left: 20px;
      right: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio .content {
      left: 20px;
      right: 20px;
    }
  }

  @media only screen and (max-width: 767px) {
    .portfolio .content {
      left: 20px;
      right: 20px;
    }
  }

  .portfolio .content .inner p {
    color: #c6c9d8;
    font-size: 14px;
  }

  .portfolio .content .inner h4 {
    font-size: 24px;
    line-height: 36px;
  }

  .portfolio .content .inner h4 a {
    color: #ffffff;
  }

  .portfolio .content .inner .portfolio-button {
    margin-top: 35px;
    -webkit-transition: 0.7s;
    -o-transition: 0.7s;
    transition: 0.7s;
  }

  @media only screen and (max-width: 575px) {
    .portfolio .content .inner .portfolio-button {
      margin-top: 25px;
    }
  }

  .portfolio .content .inner .portfolio-button a.rn-btn {
    color: #ffffff;
    border-color: rgba(255, 255, 255, 0.3);
  }

  .portfolio .content .inner .portfolio-button a.rn-btn:hover {
    border-color: #f9004d;
  }

  .portfolio.text-center .content {
    position: absolute;
    bottom: 40px;
    left: 40px;
    max-width: 80% !important;
    z-index: 10;
    padding: 0;
    right: 40px;
    margin: 0 auto;
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .portfolio.text-center .content {
      left: 20px;
      right: 20px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio.text-center .content {
      left: 15px;
      right: 15px;
    }
  }

  @media only screen and (max-width: 767px) {
    .portfolio.text-center .content {
      left: 10px;
      right: 10px;
    }
  }

  .portfolio:hover .thumbnail-inner {
    -webkit-transform: scale(1.08) translateZ(0);
    transform: scale(1.08) translateZ(0);
  }

  .portfolio:hover .thumbnail-inner::before {
    opacity: 0.85;
  }

  .portfolio:hover .thumbnail {
    -webkit-transform: scale(1) translateZ(0);
    transform: scale(1) translateZ(0);
  }

  .portfolio:hover .thumbnail::after {
    background-color: rgba(25, 25, 25, 0.24);
  }

  .portfolio:hover .bg-blr-image {
    opacity: 0;
    z-index: 9;
  }

  .portfolio.portfolio-style--2 .inner {
    margin: 0 20px;
  }

  .portfolio-tilthover .portfolio {
    margin-top: 30px;
  }

  @media only screen and (max-width: 767px) {
    .portfolio-tilthover .portfolio {
      min-height: 400px;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .portfolio-tilthover .portfolio {
      min-height: 400px;
    }
  }

  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .portfolio-tilthover .portfolio {
      min-height: 400px;
    }
  }

  .portfolio-tilthover .portfolio .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center;
    border-radius: 5px;
    background-color: #0a0a0a;
    background-image: url(../images/portfolio/portfolio-static-01.jpg);
    -webkit-transform: scale(1.13) translateZ(0);
    transform: scale(1.13) translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform .28s ease;
    transition: -webkit-transform .28s ease;
    -o-transition: transform .28s ease;
    transition: transform .28s ease;
    transition: transform .28s ease, -webkit-transform .28s ease;
    z-index: 4;
  }

  @media only screen and (max-width: 575px) {
    .portfolio-tilthover .portfolio .thumbnail {
      -webkit-transform: scale(1) translateZ(0);
      transform: scale(1) translateZ(0);
    }
  }

}

/*-------------------------------------------------------------------*/
.volunteering{
  background: transparent;

  .row{
    align-items: center;
  }
  h3{
    color: #FFF;

  }
  .slide-btn{
    margin-top: 50px;
  }
}
/*------------------------------------------------------------------*/

.activities{
  .item{
    padding: 20px;
  }
  .box{
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;

    h3{
      font-size: 20px;
      text-align: center;
    }
  }
}

.news{
  background: #f8f9fc;
  .box{
    background: #FFF;
    h3{
      font-size: 20px;
      padding: 7px 15px;
      text-align: center;
    }
  }
  img{
    width: 100%;
  }
  .news-slider{
    .item{
      padding: 20px;
    }
  }
}
/*------------------------------------------------------------------*/

.counterup_style--1 {
  text-align: center;
  margin-top: 40px;
}

.counterup_style--1 h5.counter {
  font-size: 72px;
  line-height: 80px;
  display: inline-block;
  margin-bottom: 17px;
  position: relative;
  display: inline-block;
  color: #009d12;
  margin-left: -13px;
}

@media only screen and (max-width: 767px) {
  .counterup_style--1 h5.counter {
    font-size: 45px;
    line-height: 56px;
    margin-bottom: 5px;
    margin-left: -11px;
  }
}

.counterup_style--1 h5.counter::after {
  right: -26px;
  position: absolute;
  top: -17px;
  font-size: 40px;
  content: "+";
}

.counterup_style--1 p.description {
  font-size: 18px;
  line-height: 30px;
  padding: 0 5%;
  color: rgba(29, 29, 36, 0.75);
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .counterup_style--1 p.description {
    font-size: 16px;
    line-height: 28px;
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .counterup_style--1 p.description {
    font-size: 16px;
    line-height: 28px;
    padding: 0;
  }
}

.counterup_style--1.text-left h5.counter {
  margin-left: 0;
}

.counterup_style--1.text-left p.description {
  padding: 0 7% 0 0;
}

.counterup_style--1.text-right h5.counter {
  margin-left: 0;
}

.counterup_style--1.text-right p.description {
  padding: 0 0 0 7%;
}

.counterup_style--1.text-white p.description {
  color: #c6c9d8;
}

.rn-paralax-counterup .counterup_style--1 {
  text-align: center;
}

.rn-paralax-counterup .counterup_style--1 h5.counter {
  color: #f9004d;
}

.rn-paralax-counterup .counterup_style--1 p.description {
  color: #ffffff;
}

/*--------------------------------------------------------------------------*/
.rad_team{
  background: #f8f9fc;
    .section-title {
      padding: 0 ;
      p {
        font-weight: 400;
        color: #717173;
        font-size: 19px;
        line-height: 30px;
        padding: 0;
        text-align: justify;
      }
    }
}
.team {
  margin-top: 30px;
  overflow: hidden;
  border-radius: 6px;
  position: relative;
}

.team .thumbnail {
  display: block;
  position: relative;
  z-index: 1;
}

.team .thumbnail img {
  width: 100%;
}

.team .thumbnail::after {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #fc004d), to(#000000));
  background: -webkit-linear-gradient(top, #fc004d 0, #000000 100%);
  background: -o-linear-gradient(top, #fc004d 0, #000000 100%);
  background: linear-gradient(to bottom, #fc004d 0, #000000 100%);
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: 1;
  opacity: 0;
}

.team .content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 35px 40px;
  z-index: 2;
}

.team .content h4.title {
  color: #ffffff;
  margin: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition-delay: 0.25s;
  -o-transition-delay: 0.25s;
  transition-delay: 0.25s;
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  -o-transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
}

.team .content h4.title a {
  color: #ffffff;
}

.team .content p.designation {
  color: #c6c9d8;
  font-size: 14px;
  line-height: 34px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  -o-transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  -webkit-transform: translateY(10px);
  -ms-transform: translateY(10px);
  transform: translateY(10px);
}

.team ul.social-icon {
  position: absolute;
  top: 25px;
  left: 35px;
  padding: 0;
  z-index: 2;
  list-style: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -10px;
}

.team ul.social-icon li {
  margin: 0 10px;
  -webkit-transform: translateY(8px) scale(0.8);
  -ms-transform: translateY(8px) scale(0.8);
  transform: translateY(8px) scale(0.8);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  -o-transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
  transition: all 0.45s cubic-bezier(0.23, 0.88, 0.34, 0.99);
}

.team ul.social-icon li a {
  color: #ffffff;
  font-size: 15px;
  transition: 0.3s;
}

.team ul.social-icon li a svg {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

.team ul.social-icon li a:hover svg {
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
}

.team.team-style--bottom ul.social-icon {
  top: auto;
  left: 40px;
  bottom: 105px;
}

.team.team-style--bottom .content h4.title {
  margin-bottom: 1px;
}

.team:hover .content h4.title {
  -webkit-transition-delay: 0.25s;
  -o-transition-delay: 0.25s;
  transition-delay: 0.25s;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.team:hover .content p.designation {
  -webkit-transition-delay: 0.33s;
  -o-transition-delay: 0.33s;
  transition-delay: 0.33s;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.team:hover .thumbnail::after {
  opacity: 0.85;
  top: 0;
}

.team:hover ul.social-icon li {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.team:hover ul.social-icon li:nth-child(1) {
  -webkit-transition-delay: .07692s;
  -o-transition-delay: .07692s;
  transition-delay: .07692s;
}

.team:hover ul.social-icon li:nth-child(2) {
  -webkit-transition-delay: .15385s;
  -o-transition-delay: .15385s;
  transition-delay: .15385s;
}

.team:hover ul.social-icon li:nth-child(3) {
  -webkit-transition-delay: .23077s;
  -o-transition-delay: .23077s;
  transition-delay: .23077s;
}

.team:hover ul.social-icon li:nth-child(4) {
  -webkit-transition-delay: .33077s;
  -o-transition-delay: .23077s;
  transition-delay: .23077s;
}

.team:hover ul.social-icon li:nth-child(5) {
  -webkit-transition-delay: .43077s;
  -o-transition-delay: .23077s;
  transition-delay: .23077s;
}

.team:hover .content {
  opacity: 1;
  visibility: visible;
}

.contact{
  ul{
    margin: 0;
    padding: 0;
    li{
      list-style-type: none;
    }
  }
}